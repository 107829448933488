body {
  padding: 0px;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}
a:focus ,div:focus{
  outline: none;
}
.navbar {
  background: #003057;
box-shadow: -8px 0px 25px rgba(217, 223, 231, 0.8);
}
header .navbar .container {
  justify-content: space-between;
  align-items: stretch;
}
header .navbar-expand-lg {
  padding: 2px 0 0;
}
header .navbar-dark .navbar-brand {
  margin: 0;
  padding: 0;
  align-items: center;
  display: flex;
}
header .navbar-expand-lg .navbar-collapse {
  width: auto;
  flex-grow: inherit;
  width: 60%;
  justify-content: space-between;
}
header .navbar-dark .navbar-nav .nav-link {
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  padding: 20px 15px 20px;
  margin-right: 3px;
}
header .navbar-dark .navbar-nav .nav-link:hover {
  background: #fff;
  color: #005d83;
  border-radius: 5px 5px 0 0;

}
header .navbar-dark .navbar-nav .nav-link.active {
  background: #fff;
  color: #005d83;
  border-radius: 5px 5px 0 0;
  font-weight: 700;
}
.navbar-expand-lg .navbar-collapse {
  align-items: stretch;
}
.navbar-expand-lg .navbar-nav {
  align-items: stretch;
}
header .navbar-dark .rightnav.navbar-nav {
  align-items: center;
}
header .navbar-dark .rightnav.navbar-nav .nav-link {
  padding: 0 5px;
  height: 36px;
  margin: 0 5px;
}
header .navbar-dark .rightnav.navbar-nav .nav-link.active,
header .navbar-dark .rightnav.navbar-nav .nav-link:hover{
  background: none;

}
header .navbar-dark .rightnav.navbar-nav .notification-list{position: relative; padding-right: 40px;}
header .navbar-dark .rightnav.navbar-nav .close-btn{position: absolute; right: 10px;}
header .navbar-dark .rightnav.navbar-nav .delete{position: absolute; right: 10px; top: 15px;}
header .navbar-dark .navbar-nav .username .dropdown-toggle{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #fff;
  color: #003057;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
header .navbar-dark .navbar-nav .username:hover ,header .navbar-dark .navbar-nav .username.show{
  background: #fff;  
  padding: 2px;
  border-radius: 3px 3px ;
}
header .navbar-dark .navbar-nav .username .dropdown-toggle:hover , header .navbar-dark .navbar-nav .username.show .dropdown-toggle{
  background: #003057;
  color:#fff;
}
header .navbar-dark .navbar-nav .username .dropdown-toggle::after, 
header .navbar-dark .navbar-nav .products .dropdown-toggle:after,
header .navbar-dark .navbar-nav .notification .dropdown-toggle::after{display: none;}
/* header .navbar-dark .navbar-nav .products .dropdown-toggle{
  background: url(./images/icon-menu.png) center center no-repeat;
  width: 54px;
  height: 57px;
} */

  .navbar-expand-lg .navbar-nav .dropdown-menu{padding: 0; right: 0; left: auto;margin-top:13px;}
/* header .navbar-dark .navbar-nav .products .dropdown-toggle:hover, header .navbar-dark .navbar-nav .products.show .dropdown-toggle{
  background: url(./images/icon-menu-hover.png) center center no-repeat #fff;
  border-radius: 3px 3px ;
} */
header .navbar-dark .navbar-nav .notification .dropdown-toggle{
  background: url(./images/icon-notification.png) center center no-repeat;
  width: 54px;
  height: 57px;
  margin: 0;
  padding: 0;
}
header .navbar-dark .navbar-nav .notification .dropdown-toggle:hover,
header .navbar-dark .navbar-nav .notification.show .dropdown-toggle{
  background: url(./images/icon-notification-hover.png) center center no-repeat #fff;
  border-radius: 3px 3px ;
}
header  .navbar-dark .navbar-text{color: #151515; font-weight: 700; font-size: 16px; margin-top: 5px;}
header .navbar-dark .rightnav.navbar-nav .nav-link.help{
  margin-left: 2px;
  padding-left: 0;
}
.dropdown-menu{left: auto; right: 0;}
.navbar-dark .navbar-text{padding: 0 10px; color: #151515;font-style: normal;
  font-weight: 700;
  font-size: 16px;
  border-bottom:solid 1px #D9DFE7;
  padding: 8px 22px;
  position: relative;
  width: 100%;
  display: flex
}
  .dropdown-menu{width: 320px;}
  /* header .dropdown-item{border-bottom:solid 1px #D9DFE7; padding: 8px 22px;white-space: normal;} */
  /* header .dropdown-item:last-child{border: 0;}
  header .dropdown-item img{max-width: 32px;} */
  header .navbar-text .userinfo{width: 200px; margin-left: 20px; display: flex; flex-direction: column;    word-break: break-word;}
  header .navbar-text .userinfo .maillink{font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    
    color: #8E909E;
    }
    header .navbar-text .userinfo .maillink:hover{color: #8E909E;
    }
    header .navbar-dark .rightnav.navbar-nav .nav-link.accountlink{ font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      /* identical to box height, or 162% */
      
      text-decoration-line: underline;
      
      color: #057C9E; margin: 0;}
      header .navbar-dark .rightnav.navbar-nav .clearall{text-decoration-line: underline;
      
        color: #057C9E; font-weight: normal; margin-top: 7px; }
        header .navbar-dark .navbar-nav .notification .navbar-text{display: flex; flex-direction: column;}
      header .navbar-dark .rightnav.navbar-nav .logoutlink{color: #057C9E;margin-left: 24%;width: 40%;}
      header .navbar-dark .rightnav.navbar-nav .logoutlink.nav-link.active{background: #e9ecef;color: #057C9E;}
  header .navbar-text .usericon{border-radius: 50%; width: 54px; height: 54px; background: #003057; color: #fff; display: flex; align-items: center; justify-content: center;}
.homeicons-row {
  display: flex;
  width: 100%;

  margin-top: 44px;
  flex-wrap: wrap;
  gap: 32px;
}
.homeicons {
  background: #ffffff;
  box-shadow: 0px 0px 25px #D9DFE7;
  border-radius: 10px;
  width: 31%;
  display: flex;
padding: 24px;
  align-items:flex-start;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  
  color: #5A6572;
  text-decoration: none;
}
.homeicons:hover{
  cursor: pointer;
  box-shadow: 0px 0px 25px #999999;
}
.homeicons .gridHd{
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 35px;
color: #000D1C;
margin-bottom: 12px;
}
.homeicons .gridBdr{display:flex;
  width: 100%;
  height: 6px;
  margin-bottom: 10px;
  background: #057C9E;
  border-radius: 2px;}
a.homeicons:hover {
  color: #000;
  background: #f2f2f2;
  cursor: pointer;
}
.homepage {
  padding-top: 96px;
}
.homepage h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: #000d1c;
}
.homepage h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}
.no-padding {
  padding-top: 20px;
}

.no-subscription {
  padding: 10px 0px 0px 20px;
}
.no-subscription > p {
  font-family: OpenSans;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #000d1c;
}

.no-subscription-rectangle {
  width: 100%;
  height: auto;
  margin: 20px 0 0;
  padding: 24px 0px 24px 24px;
  border-radius: 14px;
  border: solid 1.5px #358fc5;
  background-color: #e8f7ff;
}

.no-subscription-rectangle > span {
  margin: 8px 0 20px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #000d1c;
}

footer {
  background: #d9dfe7;
  width: 100%;
  min-height: 100px;
  display: flex !important;
  flex-wrap: nowrap;
  bottom: 0;
  position: relative;
  z-index: 1;
}
footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerlinks {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.footerlinks a {
  margin: 2px;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */

  color: #000d1c;
  position: relative;
  margin: 0 11px;
}
.footerlinks a::after {
  content: "|";
  width: 2px;
  height: 17px;
  position: absolute;
  right: -10px;
  top: -1px;
}
.footerlinks a:first-child {
  margin-left: 0;
}
.copyright {
  margin-left: 11px;
}
.homepage {
  padding: 30px 0 60px;
  min-height: 600px;
}
.visible-mobile {
  display: none;
}
.offcanvas .btn-close{display: none;}
@media only screen and (max-width: 1280px) {
  header .navbar-expand-lg .navbar-collapse {
    width: 70%;
  }
}

@media only screen and (max-width: 992px) {
  .visible-mobile {
    display: inline-block;
  }
  footer .container {
    width: 100%;
    padding: 20px;
    max-width: 100%;
  }
  .copyright {
    width: 100%;
    text-align: center;
  }
  .footerlinks {
    justify-content: center;
  }
  .footerlinks a:last-child::after {
    display: none;
  }
  .homeicons-row {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
  .homeicons {
    width: 45%;
  }
  header .navbar-expand-lg {
    padding: 5px 10px;
  }
  header .navbar-dark .navbar-nav .nav-link.active {
    border-radius: 5px;
  }
  header .navbar-dark .navbar-nav .nav-link {
    padding: 5px 10px;
  }
  header .navbar-collapse {
    margin-top: 10px;
  }
  header .navbar-dark .rightnav.navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu{position: absolute;}
  .navbar-brand{
    width: 35px;
    overflow: hidden;

  }
  header .navbar-dark .rightnav.navbar-nav .nav-link {
    padding: 5px 10px;
  }
  .offcanvas {display: none;}
  .offcanvas.show{display: block;}
  header .navbar-dark .navbar-nav .nav-link.username {
    width: 100%;
    background: none;
    color: #fff;
    justify-content: left;
    font-weight: normal;
  }
  .offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
  }
  .offcanvas.show {position: fixed; left: 0; top: 0;z-index: 1041; bottom: 0; width: 210px; background: #fff;}
  .offcanvas-header{display: flex; justify-content: end;}
  .offcanvas .btn-close{position: relative; width: 10px; height: 10px; display: none;}
  .offcanvas.show .btn-close{display: block; width: 25px; height: 25px;
  background: #f2f2f2; border: 0;}
  .offcanvas .btn-close::before{    content: 'x';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 7px;
    top: 0;
    font-size: 20px;
    line-height: 18px;}
  .offcanvas .navbar-nav .nav-link {
    font-style: normal;
  
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */
  
    color: #000d1c;
    padding: 8px 30px 8px 32px;
    cursor: pointer;
  }
  
  
  .offcanvas .nav-link.active {
    font-weight: 700;
  }
  
  .offcanvas .nav-link.active::before {
    content: "";
    background: #9e007e;
    height: 20px;
    width: 4px;
    position: absolute;
    left: 0;
  }
}
@media only screen and (max-width: 481px) {
  .homeicons {
    width: 100%;
  }
  .homepage h4 {
    word-wrap: break-word;
  }
}

.page-not-config{
  min-height: calc( 100vh - 120px );
  margin-top: 60px;
}
.assessment-dashboard {
  overflow: hidden;
  .gwIframe {
    .min-h-screen-bar {
      padding-bottom: 200px;
    }
  }
  .gw-footer {
    display: none !important;
  }
}

.granted .school-title {
  color: #151515;
  font-size: 16px !important;
  letter-spacing: normal;
  line-height: 24px !important;
  margin-bottom: 0!important;
  padding: 20px 0 20px 0 !important;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.not-granted .school-title {
  color: #656B74;
  font-size: 16px !important;
  letter-spacing: normal;
  line-height: 24px !important;
  margin-bottom: 0!important;
  padding: 20px 0 20px 0 !important;
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.school-status{
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  /* Heading/H4: Heading 4 */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  margin-bottom: 16px;
}
.no-schools{
  padding: 20px;
  justify-content: center;
  display: flex;
}
.card{
  border-radius: 14px !important;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.10);
}
.card.granted:hover{
  box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.20);
}
.card.granted{
  cursor: pointer;
}
.card-header{
  border-radius: 14px 14px 0px 0px !important; 
}
.card-body{
  padding: 0.5rem 1.25rem !important;
}
.school-desc{
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
}
.school-insights{
  color: #000D1C;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 12px;
}
.schoolmainwrapper {
  display: flex;
  flex-wrap: nowrap;
  background: #F8F9FA;  
  min-height: calc( 100vh - 100px )
}
.schoolmainwrapper .sidebar {
  width: 245px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 2px #f2f4f6;
}
.schoolmainwrapper .sidebar .navbar-expand-lg{
  padding: 20px 20px 0px 0px;
  width: 245px;
  border-bottom: 1.5px solid #F1F4F9 !important;
}
@media only screen and (min-width: 995px) {
  .schoolmainwrapper .sidebar .navbar-expand-lg{
    position: fixed;
    top: 60px;
  }
}
@media only screen and (max-width: 992px){
  .schoolmainwrapper {
    flex-wrap: wrap;
  }
  .schoolmainwrapper .sidebar{
    width: 100%;
  }
}

.schoolmainwrapper .sidebar .navbar-expand-lg .navbar-nav a {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #656B74;
  padding: 8px 17px 8px 35px;
  cursor: pointer;
  width: 100%;  

}

.schoolmainwrapper .sidebar .bg-light {
  background: #fff !important;
  box-shadow: none;
}

.schoolmainwrapper .sidebar .nav-link.active {
  font-weight: 700;
}
.all-school-lhs{
  cursor: default !important;
}
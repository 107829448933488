@import "~common-components-spa/dist/styles/main.scss";
@import "../../styles/custommixin";

.gwIframe {
  width: 100%;
  // TODO: change to auto once we start obtaining height from spa.
  min-height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  & .container{
    width:100%;
  }
}
.gwIframe {
  //margin-top: 25px;
}

.gwWrapper_content{
  min-height: 100vh;
  @include bp-small {
    min-height: 74vh;
  }
}

/////////// MIXIN RULES ///////////
// Mixin Functions //
@mixin OpenSansRegular {
    font-family: 'Open Sans';
    font-weight: 400;
  }
  @mixin OpenSansSemibold {
    font-family: 'Open Sans';
    font-weight: 600;
  }
  @mixin OpenSansBold {
    font-family: 'Open Sans';
    font-weight: 700;
  } 
  @mixin OpenSansExtraBold {
    font-family: 'Open Sans';
    font-weight: 800;
  }
  @mixin PlayfairRegular {
    font-family: 'Playfair Display';
    font-weight: 400;
  }
  @mixin MaterialIcons {
    font-family: 'Material Icons';
    font-style: normal;
  }
  
@mixin overlay() {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

// inclusion rule : @include overlay();
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// inclusion rule : @include center-block;
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// inclusion rule : @include text-truncate;
@mixin flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

// inclusion rule : @include flex
@mixin inline-flex {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

// inclusion rule : @include inline-flex;
// Mixin Border Radius //
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

// inclusion rule : @include border-radius(2px);
// Mixin Box Shadow //
@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
    box-shadow: $shadow;
}

// inclusion rule : @include box-shadow(0px 3px 6px 0px #bdbdbd);
// Mixin Box Sizing //
@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    -ms-box-sizing: $box-model;
    box-sizing: $box-model;
}

// inclusion rule : @include box-sizing(border-box);
// Font Face //
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
    @font-face {
        font-family: quote($font-name);
        src: url($file-name + '.eot');
        src: url($file-name + '.eot?#iefix') format('embedded-opentype'),
        url($file-name + '.woff') format('woff'),
        url($file-name + '.ttf') format('truetype'),
        url($file-name + '.svg##{$font-name}') format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}

// inclusion rule : @include font-face('gotham', '/fonts/gotham')
// Mixin Font Size //
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

// inclusion rule : @include font-size(14px)

// Mixin Breakpoints //
@mixin bp-extralarge {
    @media only screen and (max-width: 1366px) {
        @content;
    }
}

@mixin bp-large {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}

@mixin bp-extramedium {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin bp-medium {
    @media only screen and (max-width: 991px) {
        @content;
    }
}

@mixin bp-small {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin bp-verysmall {
    @media only screen and (max-width: 576px) {
        @content;
    }
}

@mixin bp-extrasmall {
    @media only screen and (max-width: 360px) {
        @content;
    }
}

// inclusion rule : @include bp-small { }
// Mixin transform and Transition //
@mixin transform($property...) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
}

// inclusion rule : @include transform(rotate(30deg)); }
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// inclusion rule : @include transition(color .3s ease); }
@mixin appearance ($value) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    appearance: $value;
}

// inclusion rule : @include appearance(none); }
// Animations and keyframes //
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}


// Cross browser opacity //
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}


// Positioning helpers
@mixin absolute($args: '') {
    @include position(absolute, $args);
}

@mixin fixed($args: '') {
    @include position(fixed, $args);
}

@mixin relative($args: '') {
    @include position(relative, $args);
}

// inclusion rule
// .menu li { @include relative; }
// .sub-menu { @include absolute(top 100% left 0); }
// .sticky-bar { @include fixed(top 0 left 0); }
// Define vertical, horizontal, or both position //
@mixin center($position) {
    position: absolute;
    @if $position=='vertical' {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    @else if $position=='horizontal' {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translate(-50%);
    }
    @else if $position=='both' {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

// inclusion rule : @include center(both)

@keyframes radioripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
    }
    50% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
    }
}

// Mixin form overlay //
@mixin form-overlay() {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
}

// inclusion rule : @include form-overlay();

// Mixin User select
@mixin user-select($value) {
    -webkit-touch-callout: $value;
    -webkit-user-select: $value;
    -khtml-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}

@mixin toastMessageCss {
    // toast message popup
    .toast-blk{
        top: 57px;
        max-width: 420px;
        color: #252525;
        font-size: $fontsize;
        @include border-radius(0);
        border: 1px solid #C7C7C7;
        @include box-shadow(0px 1px 1px rgba(0, 0, 0, 0.16));
        @include absolute(left 50%);
        @include transform(translate(-50%));
        opacity: 0;
        &.-maxwidth{
            max-width: 100%;
            min-width: 280px;
        }
        &.greetings{
            @include border-radius(8px);
            border: 1px solid #e9e9e9;
            @include OpenSansSemibold();
            background: #fff;
            @include box-shadow(none);
            .toast-body{
                padding: 1rem 1.5rem;
                @include bp-small{
                    display: flex;
                    font-size: 0.75rem;
                    align-items: center;
                    padding: .75rem;
                    white-space: nowrap;
                }
            }
            .toast-blk__img{
                width: 40px;
                height: 40px;
                margin-right: 1rem;
                @include bp-small{
                    width: 32px;
                    height: 32px;
                    margin-right: 0.75rem;
                }
            }
        }
        &.show{
            @include animation('toast-anim-open' 0.5s forwards);
            opacity: 1;
            z-index: 99991;
        }
        &.toast-close{
            opacity: 0;
            z-index: 99991;
            @include animation('toast-anim-close' 0.5s forwards);
        }
        &__img{
            width: 32px;
            height: 32px;
            margin-right: 0.75rem;
        }
        &.success{
            border-left: solid 4px #81C36C;
        }
        &.warning{
            border-left: solid 4px #db0021;
        }
        &.info{
            border-left: solid 4px #eea521;
        }
        &.hand {
            border-left: 0;
            box-shadow: none;
            border: 0;
            border-radius: 0;
            background: transparent;
            &.show{
                @include animation('toast-hand-anim-open' 0.5s forwards);
                opacity: 1;
                z-index: 99991;
            }
            &.toast-close{
                opacity: 0;
                z-index: 99991;
                @include animation('toast-hand-anim-close' 0.5s forwards);
            }
            & .toast-body {
                background: #252525;
                color: #fff;
                border-radius: 4px;
                border: 2px solid #005D83;
                padding: 0.15rem 0.25rem;
            }
            & .toast-blk__img {
                width: 18px;
                height: 18px;
                margin-right: 0.25rem;
                margin-bottom: 0.15rem;
            }
            & strong {
                font-weight: normal;
            }
        }
    }


    @include keyframes(toast-anim-open){
        from {margin-top: -100px;}
        to {margin-top: 8px;}
    }
    @include keyframes(toast-anim-close){
        from {margin-top: 0;}
        to {margin-top: -100px;}
    }

    @include keyframes(toast-hand-anim-open){
        from {bottom: -100px;}
        to {bottom: 0;}
    }
    @include keyframes(toast-hand-anim-close){
        from {bottom: 0;}
        to {bottom: -100px;}
    }
}


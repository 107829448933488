@import "~common-components-spa/dist/styles/main.scss";

header{
    position: fixed;
    z-index: 1000;
    width: 100%;
    top:0px;
}
@media only screen and (min-width: 995px) {
    
    header .navbar-dark .navbar-brand{
        background: url(./../../images/logo.png) center center no-repeat;
        height: 38px;
        width: 120px;
      }
  }
  @media only screen and (max-width: 994px) {
    header .navbar-dark .navbar-brand{
        background: url(./../../images/Pearson-mobilelogo.svg) center no-repeat;
        height: 36px;
        width: 33px !important;
      }
  }